import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { connect } from "react-redux";
import { emailRegex } from "../../libs/regexs";
import { AliasAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";

const DefaultButton = (props) => {
    return <RAYButton
        color={"primary"}
        label={props?.isEditMode ? "Config" : "Create"}
        {...props}
    />
};

const pickData = (obj) => obj?.name && _.pick(obj, ["alias", "emails", "isDel", "activated"]) || {};

const EmailAliasEditModal = ({ slotButton, item, UpdateEmailAliasItem, auth }) => {
    const [show, setShow] = useState(false);
    const isEditMode = useMemo(() => !!item?.name, [item?.name]);
    const [data, setData] = useState(pickData(item));
    const hasChangedData = useMemo(() => !_.isEqual(pickData(item), data), [item, data]);
    const [loading, setLoading] = useState(false);
    const [removeConfirmed, setRemoveConfirmed] = useState(false);
    const [inputEmailString, setInputEmailString] = useState("");
    const inputEmailList = useMemo(() => {
        if (!inputEmailString?.trim()) {
            return [];
        }
        let _inputEmailList = inputEmailString.split("\n");
        _inputEmailList = _inputEmailList.reduce((acc, cur) => {
            const _inArr = cur.split(",");
            acc = [...acc, ..._inArr];
            return acc;
        }, []);
        _inputEmailList = _inputEmailList.map(x => x.trim()).filter(x => emailRegex.test(x));
        _inputEmailList = [...(new Set(_inputEmailList))];
        return _inputEmailList;
    }, [inputEmailString]);

    useEffect(() => {
        show && setData(pickData(item));
    }, [item, show]);

    const closeModal = useCallback(() => {
        setShow(false);
        setRemoveConfirmed(false);
        setInputEmailString("");
        setLoading(false);
    }, []);

    const handlerClickAddEmails = useCallback(() => {
        if (inputEmailList?.length === 0) {
            return;
        }
        const existEmails = [...(data?.emails || []), ...(item?.emails || [])];
        let _inputEmailList = inputEmailList.filter(x => !existEmails.includes(x));
        _inputEmailList = [...(new Set(_inputEmailList))];
        if (_inputEmailList?.length > 0) {
            setData(prev => ({ ...prev, emails: [...(prev?.emails || []), ..._inputEmailList] }));
        }
        setInputEmailString("");
    }, [inputEmailList, item?.emails, data?.emails]);

    const handlerEnterInput = useCallback((event) => {
        if (event.shiftKey && event.key === "Enter" && !event.altKey && !event.ctrlKey) {
            handlerClickAddEmails();
            event.preventDefault();
        }
    }, [handlerClickAddEmails])

    const handlerUpdate = async () => {
        try {
            setLoading(true);
            const _item = { ..._.pick(item, ["name", "type"]), ...data };
            _item.updater = auth?.sub;
            _item.updated = new Date().getTime();
            await UpdateEmailAliasItem(_item);
        } catch (error) {
            console.log("ERROR[handlerUpdate]", error?.response?.data || error?.toString());
        } finally {
            setLoading(false);
        }
    };

    const handlerCreate = async () => {
        try {
            setLoading(true);
            const _item = { ...data };
            _item.name = `ae-${window?.crypto?.randomUUID() || new Date().getTime()}`;
            _item.creator = auth?.sub;
            _item.created = new Date().getTime();
            await UpdateEmailAliasItem(_item);
            closeModal();
        } catch (error) {
            console.log("ERROR[handlerCreate]", error?.response?.data || error?.toString());
        } finally {
            setLoading(false);
        }
    };

    const handlerRemove = async () => {
        try {
            if (!removeConfirmed) {
                setLoading(true);
                setTimeout(() => {
                    setRemoveConfirmed(true);
                    setLoading(false);
                }, 500);
                return;
            }
            setLoading(true);
            const _item = { ..._.pick(item, ["name", "type"]), isDel: true };
            _item.updater = auth?.sub;
            _item.updated = new Date().getTime();
            await UpdateEmailAliasItem(_item);
            closeModal();
        } catch (error) {
            console.log("ERROR[handlerRemove]", error?.response?.data || error?.toString());
            setRemoveConfirmed(false);
        } finally {
            setLoading(false);
        }
    };

    return <>
        {slotButton && slotButton?.props ? <>
            {React.cloneElement(slotButton, { ...slotButton.props, onClick: () => setShow(true) })}
        </> : <>
            <DefaultButton isEditMode={isEditMode} onClick={() => setShow(true)} />
        </>}
        <Modal
            show={show}
            size="lg"
            centered={true}
            onHide={closeModal}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    {isEditMode ? `Config "${item?.alias}" group` : "Create e-mail group"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <table className="table">
                        <colgroup>
                            <col width={150} />
                            <col />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>Alias Name</th>
                                <td>
                                    <RAYTextField
                                        name="alias-name"
                                        value={data?.alias || ""}
                                        onChange={event => setData(prev => ({ ...prev, alias: event?.target?.value }))}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>e-mail</th>
                                <td>
                                    {data?.emails?.length === 0 && <>
                                        <i>No e-mail</i>
                                    </>}
                                    <div>
                                        <div className="d-flex align-items-center gap-2 flex-wrap mb-2">
                                            {data?.emails?.sort()?.map(x => {
                                                const isExistEmail = item?.emails?.includes(x);
                                                const removeItem = () => setData(prev => ({ ...prev, emails: (prev?.emails || []).filter(y => y !== x) }));
                                                return <React.Fragment key={x}>
                                                    <div
                                                        xs={6}
                                                        className="d-flex align-items-center gap-1"
                                                        style={{
                                                            width: "48%",
                                                            borderBottom: "1px solid #ddd",
                                                        }}
                                                    >
                                                        {!isExistEmail && <>
                                                            <i className="flex-shrink-0" style={{ fontSize: 10 }}>New</i>
                                                        </>}
                                                        <div
                                                            className="px-1 text-black"
                                                            style={{ wordBreak: "break-all" }}
                                                        >
                                                            {x}
                                                        </div>
                                                        <MdClose
                                                            className="cursor-pointer text-danger flex-shrink-0"
                                                            onClick={removeItem}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            })}
                                        </div>
                                        <textarea
                                            className="form-item w-100"
                                            placeholder="Enter emails, ex) email-1@test.test, email-2@test.test, email-3@test.test ↵ email-4@test.test"
                                            value={inputEmailString}
                                            style={{
                                                height: 100,
                                                maxHeight: 300,
                                            }}
                                            onKeyDown={handlerEnterInput}
                                            onChange={event => setInputEmailString(event?.target?.value)}
                                        />
                                        <div className="d-flex align-items-center justify-content-end gap-1">
                                            <small>
                                                {inputEmailList?.length > 0 && <>
                                                    Shift + Enter
                                                </>}
                                            </small>
                                            <div className="flex-fill" />
                                            <RAYButton
                                                label={"Clear"}
                                                color={"secondary"}
                                                onClick={() => setInputEmailString("")}
                                                size={"sm"}
                                            />
                                            <RAYButton
                                                label={"Add"}
                                                disabled={inputEmailList?.length === 0}
                                                color={"primary"}
                                                onClick={handlerClickAddEmails}
                                                size={"sm"}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Activated</th>
                                <td>
                                    <RAYToggle
                                        value={data?.activated}
                                        onChange={event => setData(prev => ({ ...prev, activated: event.target.checked }))}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th />
                                <td>
                                    <div className="d-flex justify-content-end gap-2">
                                        {isEditMode && <>
                                            <RAYButton
                                                label={!removeConfirmed ? "Remove" : "Confirm Remove"}
                                                color={!removeConfirmed ? "danger" : "warning"}
                                                onClick={handlerRemove}
                                            />
                                        </>}
                                        <RAYButton
                                            label={isEditMode ? "Update" : "Create"}
                                            color="primary"
                                            disabled={loading || !data.alias || (item?.name && !hasChangedData)}
                                            onClick={() => {
                                                if (isEditMode) {
                                                    handlerUpdate();
                                                } else {
                                                    handlerCreate();
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    </>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    return { auth };
};

const mapDispatch = (dispatch) => ({
    UpdateEmailAliasItem: (data) => dispatch(AliasAction.UpdateEmailAliasItem(data)),
});

export default connect(mapState, mapDispatch)(EmailAliasEditModal);
